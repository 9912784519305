<template>
    <div class="card p-3">
        <div class="row gutters">
            <h4 class="col-12">
                <i class="icon-layers2"></i> {{ t('HOME.DOCUMENT') }}</h4>
            <div class="col-12 p-3 d-flex"
                 style="gap: 12px"
                 :class="!$widthMd(690) ? '' : 'flex-wrap'">
                <slot name="cards"></slot>
            </div>
            <div
                    class="col-12 d-flex justify-content-end pointer"
                    @click="$emit('toRoute', 'All')">
                <i class="icon-documents mr-1" style="font-size: 18px"/><h5> {{ t("HOME.VIEW_ALL_DOC") }}</h5></div>
        </div>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';

export default {
    name: "cardDocumentsComponent",
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    emits:['toRoute'],
}
</script>

<style scoped>

</style>