<template>
    <div class="card">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="pt-3 row">
                <div class="col-12 d-flex flex-row justify-content-center">
                    <h5 class="card-title">
                        {{ this.t('HOME.RESUM_ASSIGN') }}
                    </h5>
                </div>
                <div class="main-container col-12" :class="loadChart ? 'filterBlur' : ''"
                     style="height: 40vh">
                    <slot name="graficPlot"></slot>
                </div>
            </div>
        </div>
        <div class=" col-12 d-flex justify-content-center w-100 pb-3">
            <slot name="buttonsAlterDay"></slot>
        </div>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import {mapState} from 'vuex';

export default {
    name: "cardGraficResumAssingComponent",
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    computed: {
        ...mapState({
            loadChart: (state) => state.home.loadChart
        })
    }
}
</script>

<style scoped>
.filterBlur {
    filter: blur(5px)
}
</style>