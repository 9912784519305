<template>
    <div class="d-flex flex-column p-3 flex-grow-1"
         :class="!$widthMd(690) ?'col-6' : 'col-12'"
         style="background: linear-gradient(90deg,#d5efef 40%,#a8c9cb 100%); border-radius: 15px ">
        <select2
                :settings="{width: !$widthMd(690) ? '40%' : '80%'}"
                :options="optionsFilterDay"
                v-model="daysFilter"
                @select="$emit('getResum', daysFilter)">
        </select2>
        <div class="row gutters mt-4 p-1">
            <div class="col-4 pointer"
                    style="color: #2e644a"
                    @click="$emit('toRoute','Signed')">
                <h2 class="font-weight-bold pl-1"> {{ resumStatusAssign?.closed }} </h2>
                <h6 class="mt-1"><i class="icon-check"></i> {{ t("HOME.ASSINED") }}</h6>
            </div>
            <div class="col-4 pointer"
                 style="color: #584d07"
                 @click="$emit('toRoute','Expired')">
                <h2 class="font-weight-bold pl-1">
                    {{ resumStatusAssign?.expired }}
                </h2>
                <h6 class="mt-1"><i class="icon-cached"></i> {{ t("HOME.EXPIRED") }}</h6>
            </div>
            <div class="col-4 pointer"
                 style="color: #9d1c1c"
                 @click="$emit('toRoute','Canceled')">
                <h2 class="font-weight-bold pl-1"> {{ resumStatusAssign?.canceleds }} </h2>
                <h6 class="mt-1"><i class="icon-cancel"></i> {{ t("HOME.CANCELED") }}</h6>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import {mapState} from 'vuex';
import Select2 from 'vue3-select2-component';

export default {
    name: "cardAssignsByDayComponent",
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    components: {
        Select2
    },
    emits: ['toRoute', 'getResum'],
    computed: {
        ...mapState({
            resumStatusAssign: (state) => state.home.resumStatusAssign,
        })
    },
    data() {
        return {
            daysFilter: '7',
            optionsFilterDay:[
                {id: '7', text: 'Últimos 07 Dias'},
                {id: '15', text: 'Últimos 15 Dias'},
                {id: '20', text: 'Últimos 20 Dias'},
                {id: 'lastMonth', text: 'Último Mês'},
                {id: 'monthNow', text: 'Mês Atual'},
            ],
        }
    }
}
</script>

<style scoped>

</style>