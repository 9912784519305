<template>
    <div>
        <loadingScreen/>
        <page-header title="Home" @setSystem="alterSystem($event)" :system="sistems"></page-header>
        <cardDocumentsComponent @toRoute="toRoute">
            <template #cards>
                <card-now-moment-component @toRoute="toRoute"></card-now-moment-component>
                <card-assigns-by-day-component @getResum="getResum" @toRoute="toRoute"></card-assigns-by-day-component>
            </template>
        </cardDocumentsComponent>
        <card-grafic-resum-assing-component>
            <template #graficPlot>
                <div id="chartAssigns"></div>
            </template>
            <template #buttonsAlterDay>
                <div class="btn btn-primary mr-2"
                     @click="getGraficDashboard({isDay: true, sistems: this.sistems});grapicDay = true"
                     :class="grapicDay ?'active' :''">
                    <i v-if="grapicDay"
                       class="icon-check-circle">
                    </i>
                    {{ t('HOME.LAST_30_DAYS') }}
                </div>
                <div class="btn btn-primary "
                     :class="grapicDay ?'' :'active'"
                     @click="getGraficDashboard({isDay: false, sistems: this.sistems});grapicDay = false">
                    <i v-if="!grapicDay"
                       class="icon-check-circle">
                    </i>
                    {{ t('HOME.LAST_12_MONTHS') }}
                </div>
            </template>
        </card-grafic-resum-assing-component>
    </div>
</template>

<script>
import LoadingScreen from '@/components/layouts/loadScreenComponent';
import PageHeader from '@/components/layouts/pageHeader';
import {mapActions, mapState, mapMutations} from 'vuex';
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import ApexCharts from 'apexcharts';
import cardNowMomentComponent from '@/pages/home/.partials/cardNowMomentComponent';
import cardAssignsByDayComponent from '@/pages/home/.partials/cardAssignsByDayComponent';
import cardDocumentsComponent from '@/pages/home/.partials/cardDocumentsComponent';
import cardGraficResumAssingComponent from '@/pages/home/.partials/cardGraficResumAssingComponent';

export default {
    name: 'HomeComponent',
    components: {
        LoadingScreen,
        PageHeader,
        cardNowMomentComponent,
        cardAssignsByDayComponent,
        cardDocumentsComponent,
        cardGraficResumAssingComponent,
    },
    beforeRouteLeave(to, from, next) {
        this.chartGraphicAssign?.destroy();
        return next();
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            chartGraphicAssign: null,
            daysFilter: '7',
            grapicDay: true,
            sistems: 'all',
        }
    },
    computed: {
        ...mapState({
            dataGrafic: (state) => state.home.dataGrafic,
        })
    },
    mounted() {
        this.getCountPendingSign({sistems: this.sistems});
        this.getCountStatusAssign({sistems: this.sistems});
        this.getGraficDashboard({isDay: true, sistems: this.sistems});
    },
    watch: {
        dataGrafic(result) {
            if (Object.keys(result)?.length) {
                this.renderChartAssing(result);
            } else {
                this.chartGraphicAssign?.destroy();
            }
        }
    },
    methods: {
        ...mapActions({
            getCountPendingSign: 'getCountPendingSign',
            getCountStatusAssign: 'getCountStatusAssign',
            getCountStatusAssignByDate: 'getCountStatusAssignByDate',
            getGraficDashboard: 'getGraficDashboard',
        }),
        ...mapMutations({
            setFilterHome: 'setFilterHome'
        }),
        alterSystem(system)
        {
            this.sistems = system;
            this.getCountPendingSign({sistems: this.sistems});
            this.getCountStatusAssignByDate({days: this.daysFilter, sistems: this.sistems});
            this.getGraficDashboard({isDay: this.grapicDay, sistems: this.sistems});
        },
        toRoute(name) {
            if (name === 'Running' || name === 'PendingAdm' || name === 'All') {
                this.setFilterHome({last_days: 'all', sistems: this.sistems});
            } else {
                this.setFilterHome({last_days: this.daysFilter, sistems: this.sistems});
            }
            this.$router.push({name})
        },
        getResum(days) {
            this.daysFilter = days;
            this.getCountStatusAssignByDate({days: this.daysFilter,sistems: this.sistems});
        },
        renderChartAssing(result) {
            let seriesAssign = [];
            seriesAssign.push({
                name: result.name1,
                data: result.assinaturas
            });
            seriesAssign.push({
                name: result.name2,
                data: result.assinados
            });
            seriesAssign.push({
                name: 'Aguardando',
                data: result.aguardando
            });
            seriesAssign.push({
                name: result.name4,
                data: result.cancelados
            });
            seriesAssign.push({
                name: result.name5,
                data: result.expirados
            });
            let chartOptionsAssign = {
                chart: {
                    height: '100%',
                    type: 'area',
                    width: '100%',
                    defaultLocale: 'pt',
                    locales: [{
                        name: 'pt',
                        options: {
                            toolbar: {
                                download: 'Download SVG',
                                selection: 'Selecionar',
                                selectionZoom: 'Selecionar Zoom',
                                zoomIn: 'Aumentar Zoom',
                                zoomOut: 'Reduzir Zoom',
                                pan: 'Panorama',
                                reset: 'Resetar Zoom',
                            }
                        }
                    }]
                },
                responsive: [
                    {
                        breakpoint: 1000,
                        options: {
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ],
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                },
                noData: {
                    text: 'Nenhum dado para ser carregado',
                    align: 'center',
                    verticalAlign: 'middle',
                },
                series: seriesAssign,
                xaxis: {
                    convertedCatToNumeric: false,
                    categories: result.categories
                },
                colors: ['#36848d', '#2e644a', '#092f6b', '#9d1c1c', '#584d07'],
            };
            let elChartAssign = document.getElementById('chartAssigns');
            this.chartGraphicAssign?.destroy();
            this.chartGraphicAssign = new ApexCharts(elChartAssign, chartOptionsAssign);
            this.chartGraphicAssign.render();
            this.$store.commit('changeLoading', false);
        }
    }
}
</script>

<style>

</style>
