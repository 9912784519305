<template>
    <div class="d-flex flex-column p-3 flex-grow-1"
         :class="!$widthMd(690) ?'col-6' : 'col-12'"
         style="background: linear-gradient(90deg,#dcefe9 40%,#b2e3d0 100%); border-radius: 15px;">
        <h4 class="font-weight-bold">
            {{ t('HOME.NOW_MOMENT') }}
        </h4>
        <div class="row gutters mt-4 p-1">
            <div
                    class="col-6 pointer"
                    style="color: #092f6b"
                    @click="$emit('toRoute', 'Running')">
                <h2 class="font-weight-bold pl-1"> {{ resumStatusAssign?.running }} </h2>
                <h6
                        class="mt-1"><i
                        class="icon-schedule"></i> {{ t("HOME.RUNNING") }}</h6>
            </div>
            <div class="col-6 pointer"
                 style="color: #79430d"
                 @click="$emit('toRoute', 'PendingAdm')">
                <h2 class="font-weight-bold pl-1">{{ resumPendingAdm }}</h2>
                <h6 class="mt-1"><i
                        class="icon-recent_actors"></i> {{ t("HOME.PENDING_ADM") }}</h6>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import {mapState} from 'vuex';

export default {
    name: "cardNowMomentComponent",
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    emits:['toRoute'],
    computed: {
        ...mapState({
            resumPendingAdm: (state) => state.home.resumPendignAdm,
            resumStatusAssign: (state) => state.home.resumStatusAssign,
        })
    },
}
</script>

<style scoped>

</style>